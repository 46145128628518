import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic';
import { FormattedMessage } from 'react-intl';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  // PortletHeaderTitle,
  // PortletHeaderToolbar
} from '../Portlet';

export const TopListDisplay = ({
  className,
  colWidth = 4,
  title = '',
  listData = [],
}) => {
  return (
    <div className={'col-' + colWidth}>
      <Portlet className={className}>
        <PortletHeader title={title} className="kt-portlet__head--noborder" />
        <PortletBody fluid={true}>
          <table className={'table stats-table'}>
            <tbody>
              {listData.map((item, index) => (
                <tr key={index}>
                  {item.image && (
                    <td>
                      <img
                        src={toAbsoluteUrl(item.image)}
                        width={15}
                        alt="icon"
                      />
                    </td>
                  )}
                  {item.strong && (
                    <td>
                      <strong>
                        <FormattedMessage id={item.strong} />
                      </strong>
                    </td>
                  )}
                  {item.title && (
                    <td>
                      <FormattedMessage id={item.title} />
                    </td>
                  )}
                  <td>{item.label}</td>
                  {item.enumerate !== undefined && item.count !== null && (
                    <td>{item.enumerate}</td>
                  )}
                  {item.count !== undefined && item.count !== null && (
                    <td>{item.count}</td>
                  )}
                  {item.price !== undefined && item.price !== null && (
                    <td>
                      <span style={{ fontWeight: 600 }}>{item.price}</span>
                    </td>
                  )}
                  {item.revenue !== undefined && item.revenue !== null && (
                    <td>
                      <span style={{ fontWeight: 600 }}>{item.revenue}</span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </PortletBody>
      </Portlet>
    </div>
  );
};
