import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../partials/content/Portlet';
import { useHistory } from 'react-router-dom';
import { getUser, updateUser } from '../../../store/users/actions';

import { authActions } from '../../../store/ducks/auth.duck';
import { Col, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCountries } from '../../../store/countries/actions';
import AddressForm from '../users/AddressForm';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

const Settings = ({ userId, user, countriesData, checkAuth }) => {
  const localStorageActiveTabKey = 'builderActiveTab';
  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [tab, setTab] = useState(activeTab ? +activeTab : 0);
  const [fields, setFields] = useState({});
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({
    shipping: {
      first_name: false,
      last_name: false,
      address: false,
      house_number: false,
      postal_code: false,
      country: false,
      tax_number_validated: false,
      tax_number: false,
      phone_number: false,
      city: false,
    },
    billing: {
      first_name: false,
      last_name: false,
      address: false,
      house_number: false,
      postal_code: false,
      country: false,
      tax_number_validated: false,
      tax_number: false,
      phone_number: false,
      city: false,
    },
    payment_type: false,
  });
  const [password, setPassword] = useState({
    current: '',
    new: '',
    confirm: '',
  });
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState({
    billing: {},
    shipping: {},
  });

  const history = useHistory();
  const userID = userId ?? null;

  useEffect(() => {
    dispatch(getCountries());
    if (userID) {
      dispatch(getUser(userID));
    }
  }, []);

  useEffect(() => {
    if (userID) {
      dispatch(getUser(userID));
    }
  }, [activeTab]);

  useEffect(() => {
    if (user) {
      updateFields(user);
      updateAddresses(user);
    }
  }, [user, activeTab]);

  useEffect(() => {
    localStorage.setItem(localStorageActiveTabKey, tab);
  }, [tab]);

  useEffect(() => {
    if (countriesData) {
      setCountries(countriesData);
    }
  }, [countriesData]);

  useEffect(() => {
    if (
      addresses?.shipping?.country === undefined ||
      addresses?.shipping?.country === null
    ) {
      setErrors({
        ...errors,
        shipping: {
          country: true,
        },
      });
    } else {
      setErrors({
        ...errors,
        shipping: {
          country: false,
        },
      });
    }
  }, [addresses?.shipping?.country]);

  useEffect(() => {
    if (
      addresses?.billing?.country === undefined ||
      addresses?.billing?.country === null
    ) {
      setErrors({
        ...errors,
        billing: {
          country: true,
        },
      });
    } else {
      setErrors({
        ...errors,
        billing: {
          country: false,
        },
      });
    }
  }, [addresses?.billing?.country]);

  const updateFields = (user) => {
    const shippingAddress = user.addresses?.find(item => item?.type?.title === 'Shipping');
  
    setFields({
      company: {
        id: user.company?.id,
        name: shippingAddress?.company_name,
      },
      email: user.email,
      email_invoices: user.email_invoices,
      first_name: shippingAddress?.first_name,
      last_name: shippingAddress?.last_name,
      username: user.username,
    });
  };
  
  const updateAddresses = (user) => {
    const shippingAddress = user.addresses?.find(item => item?.type?.title === 'Shipping');
    const billingAddress = user.addresses?.find(item => item?.type?.title === 'Billing');
  
    setAddresses({
      billing: { ...billingAddress },
      shipping: { ...shippingAddress },
    });
  };

  const onChange = (field) => (event) => {
    setFields({
      ...fields,
      [field]: event.target.value,
    });
  };

  const onChangeCompany = (event) => {
    setFields({
      ...fields,
      company: {
        ...fields.company,
        name: event.target.value,
      },
    });
  };

  const onChangePassword = (field) => (event) => {
    setPassword({
      ...password,
      [field]: event.target.value,
    });
  };

  const onChangeBilling = (value, field) => {
    setAddresses({
      ...addresses,
      billing: {
        ...addresses.billing,
        [field]: value,
      },
    });
  };

  const onChangeShipping = (value, field) => {
    setAddresses({
      ...addresses,
      shipping: {
        ...addresses.shipping,
        [field]: value,
      },
    });
  };

  const onSubmitUser = () => {
    dispatch(
      updateUser({
        id: userID,
        data: fields,
      })
    );

    toast.success(intl.formatMessage({ id: 'User saved succesfully' }), {
      position: 'bottom-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const onSubmitAddresses = async () => {
    let payload = {
      shipping_address: {
        ...addresses.shipping,
        type: 1,
      },
      billing_address: {
        ...addresses.billing,
        type: 2,
      },
    };

    if (!payload.shipping_address?.company_name) {
      payload.shipping_address.tax_number = '';
    }

    if (!payload.billing_address?.company_name) {
      payload.billing_address.tax_number = '';
    }

    if (payload.shipping_address.tax_number) {
      if (errors?.shipping?.tax_number_validated) {
        payload.shipping_address.tax_number_valid = false;
      } else {
        payload.shipping_address.tax_number_valid = true;
      }
    }

    if (payload.billing_address.tax_number) {
      if (errors?.billing?.tax_number_validated) {
        payload.billing_address.tax_number_valid = false;
      } else {
        payload.billing_address.tax_number_valid = true;
      }
    }

    dispatch(
      updateUser({
        id: userID,
        data: payload,
        successCallBack: () => {
          checkAuth(history);
        },
      })
    );

    toast.success('Addresses saved succesfully', {
      position: 'bottom-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const onSubmitPassword = () => {
    if (!password.new || !password.current) {
      setError('Password is empty');
    } else if (password.new !== password.confirm) {
      setError('Passwords not equal');
    } else {
      setError('');
      let data = {
        id: userID,
        currentpassword: password.current,
        newpassword: password.new,
      };
      dispatch(authActions.updatePassword(data));
    }
  };

  const intl = useIntl();

  return (
    <>
      <ToastContainer />

      <div className="kt-form kt-form--label-right">
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <Tabs
                  component="div"
                  className="builder-tabs"
                  value={tab}
                  onChange={(_, nextTab) => {
                    setTab(nextTab);
                    localStorage.setItem(localStorageActiveTabKey, nextTab);
                  }}
                >
                  <Tab label={intl.formatMessage({ id: 'Customer info' })} />
                  {/* <Tab label="Page" /> */}
                  <Tab label={intl.formatMessage({ id: 'Adresses' })} />
                  <Tab label={intl.formatMessage({ id: 'Password' })} />
                </Tabs>
              </PortletHeaderToolbar>
            }
          />

          {tab === 0 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-30">
                <div className="kt-section__body w-100">
                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      <h3 className="kt-section__title kt-section__title-sm">
                        <FormattedMessage id={'Customer info'} />
                      </h3>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'CHECKOUT.COMPANY_NAME'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <input
                        className="form-control"
                        type="text"
                        value={fields?.company?.name || ''}
                        onChange={(evt) => {
                          onChangeCompany(evt);
                        }}
                      />
                      <span className="form-text text-muted">
                        <FormattedMessage
                          id={
                            'If you want your invoices addressed to a company. Leave blank to use your private name.'
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'CHECKOUT.FIRST_NAME'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <input
                        className="form-control"
                        type="text"
                        value={fields.first_name || ''}
                        onChange={onChange('first_name')}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'CHECKOUT.LAST_NAME'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <input
                        className="form-control"
                        type="text"
                        value={fields.last_name || ''}
                        onChange={onChange('last_name')}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'REGISTER.EMAIL'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="la la-at"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          value={fields.email || ''}
                          onChange={onChange('email')}
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'REGISTER.EMAIL_BILLING'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="la la-at"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          value={fields.email_invoices || ''}
                          onChange={onChange('email_invoices')}
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid"></div>

                  <div className="kt-form__actions">
                    <div className="row">
                      <div className="col-xl-3"></div>
                      <div className="col-lg-9 col-xl-6">
                        <a
                          className="btn btn-label-brand btn-bold"
                          onClick={onSubmitUser}
                        >
                          <FormattedMessage id={'Save'} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 1 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-30">
                <div className="kt-section__body w-100">
                  <Row>
                    <Col md={6}>
                      <AddressForm
                        onChange={onChangeShipping}
                        countries={countries}
                        type={'shipping'}
                        isBilling={false}
                        setIsBilling={() => {}}
                        updateTax={() => {}}
                        changeAddress={addresses.shipping}
                        onChangeAddressValue={onChangeShipping}
                        errors={errors}
                        setErrors={setErrors}
                        order={{ id: 1 }}
                      />
                    </Col>
                    <Col md={6}>
                      <AddressForm
                        onChange={onChangeBilling}
                        countries={countries}
                        type={'billing'}
                        isBilling={true}
                        setIsBilling={() => {}}
                        updateTax={() => {}}
                        changeAddress={addresses.billing}
                        onChangeAddressValue={onChangeBilling}
                        errors={errors}
                        setErrors={setErrors}
                        order={{ id: 2 }}
                      />
                    </Col>
                  </Row>
                  <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid"></div>
                  <div className="kt-form__actions">
                    <div className="row">
                      <div className="col-xl-3"></div>
                      <div className="col-lg-9 col-xl-6">
                        <a
                          className="btn btn-label-brand btn-bold"
                          onClick={onSubmitAddresses}
                        >
                          <FormattedMessage id={'Save'} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}

          {tab === 2 && (
            <PortletBody>
              <div className="kt-section kt-margin-t-30">
                <div className="kt-section__body w-100">
                  {error && (
                    <div
                      className="alert alert-solid-danger alert-bold fade show kt-margin-t-20 kt-margin-b-40"
                      role="alert"
                    >
                      <div className="alert-icon">
                        <i className="fa fa-exclamation-triangle"></i>
                      </div>
                      <div className="alert-text">{error}</div>
                      <div className="alert-close">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            <i className="la la-close"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      <h3 className="kt-section__title kt-section__title-sm">
                        <FormattedMessage id={'Change password'} />
                      </h3>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'Current password'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <input
                        type="password"
                        className="form-control"
                        value={password.current || ''}
                        onChange={onChangePassword('current')}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'New password'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <input
                        type="password"
                        className="form-control"
                        value={password.new || ''}
                        onChange={onChangePassword('new')}
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-last row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      <FormattedMessage id={'Verify password'} />
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <input
                        type="password"
                        className="form-control"
                        value={password.confirm || ''}
                        onChange={onChangePassword('confirm')}
                      />
                    </div>
                  </div>
                </div>
                <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid"></div>
                <div className="kt-form__actions">
                  <div className="row">
                    <div className="col-xl-3"></div>
                    <div className="col-lg-9 col-xl-6">
                      <a
                        className="btn btn-label-brand btn-bold"
                        onClick={onSubmitPassword}
                      >
                        <FormattedMessage id={'Save'} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </PortletBody>
          )}
        </Portlet>
      </div>
    </>
  );
};

const mapStateToProps = ({
  auth: { user },
  users: { detailedUser },
  countries,
}) => ({
  userId: user.id,
  user,
  countriesData: countries.data,
});
const mapDispatchToProps = {
  checkAuth: authActions.checkAuth,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
