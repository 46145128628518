import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button, FormText } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { Paper } from '@material-ui/core';
import { updatePriceRule, getPriceRule } from '../../../store/priceRules/actions';
import { getUsers } from '../../../store/users/actions';
import {
  PRICE_RULE_TYPES,
  PRICE_RULE_ELEMENTS,
  PRICE_RULE_ACTIONS,
  PAYMENT_TYPES
} from '../../../common/constants/constants';

import { ROUTES } from '../../../common/constants/routes/routes';

const DetailsPage = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing, usersData } = props;
  const [form, setForm] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);

  const fields = [
    {
      title: 'Type',
      name: 'type',
      type: 'select',
      required: true,
      multiple: false,
      options: PRICE_RULE_TYPES
    },
    {
      title: 'Payment Types',
      name: 'payment_types',
      type: 'select',
      description: 'Only applicable when Type is set to Payment Type',
      required: true,
      multiple: true,
      options: PAYMENT_TYPES
    },
    {
      title: 'Users',
      name: 'users',
      type: 'select',
      description: 'Leave empty for the price rule to apply on all users',
      required: false,
      multiple: true,
      options: users
    },
    {
      title: 'Title',
      name: 'title',
      type: 'text',
      required: true
    },
    {
      title: 'Range from',
      name: 'range_from',
      type: 'float',
      required: true
    },
    {
      title: 'Range to',
      name: 'range_to',
      type: 'float',
      required: true
    },
    {
      title: 'Do action',
      name: 'action',
      type: 'select',
      required: true,
      options: PRICE_RULE_ACTIONS
    },
    {
      title: 'Amount',
      name: 'amount',
      type: 'float',
      required: true
    },
    {
      title: 'On element',
      name: 'element',
      type: 'select',
      required: true,
      options: PRICE_RULE_ELEMENTS
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getPriceRule(id));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getUsers());
  }, []); // eslint-disable-line

  useEffect(() => {
    let formatUsersData = usersData.map((user) => {
      return {
        id: user.id,
        title: user.first_name + ' ' + user.last_name + ' - ' + user.email
      }
    })
    setUsers(formatUsersData);
  }, [usersData]);

  useEffect(() => {

    if (data && id) {
      let newUsers = [];
      data.users.forEach(function (user) {
        newUsers.push({
          id: user.id,
          title: user.first_name + ' ' + user.last_name + ' - ' + user.email
        });
      });

      data.users = newUsers;

      setForm(data);
      setLoading(false);

    }

    if(!id) {

      setForm({
        'type': 1,
        'action': 1,
        'element': 1,
        'title': '',
        'range_from': 0,
        'range_to': 0,
        'amount': 0,
      });

      setLoading(false);
    }

  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {};

    fields.forEach(field => {

      switch (field.type) {

        case 'integer':
          payload[field.name] = parseInt(form[field.name]);
          break;

        case 'float':
          payload[field.name] = parseFloat(form[field.name]);
          break;

        case 'select':
          if (field.multiple) {
            let payloadSelect = [];
            form[field.name] && form[field.name].length > 0 && form[field.name].forEach((item) => {
              payloadSelect.push({ id: item.id });
            });
            payload[field.name] = payloadSelect;

          }else{
            payload[field.name] = form[field.name];
          }
          break;
        default:
          payload[field.name] = form[field.name];
      }
    });

    dispatch(updatePriceRule({
      id: id,
      data: payload,
      successCallBack: () => {
        history.push(ROUTES.PRICE_RULES);
      }
    }));
  };

  const handleCancel = () => {
    history.push(ROUTES.PRICE_RULES);
  };

  // const onChangeSelect = (field, event) => {
  //   setForm({...form, [field.name]: event.target.value
  //   });
  // };

  const onChange = field => event => {
    setForm({...form, [field]: event.target.value});
  }

  return isProcessing || loading ? 'Loading...' : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">Price rule {id ? 'Edit' : 'Add'}</h1>

              <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
              >

                {fields.map(field => (
                    <Form.Group key={field.name} as={Row} controlId="">
                      <Form.Label column sm={2}>{field.title}</Form.Label>
                      <Col sm={10}>
                        {field.type === "text" && <Form.Control
                            type="text"
                            defaultValue={form[field.name] || ''}
                            onChange={onChange(field.name)}
                            required={field.required}
                        />}
                        {field.type === 'float' && <Form.Control
                            type="text"
                            defaultValue={form[field.name] || ''}
                            onChange={onChange(field.name)}
                            required={field.required}
                        />}
                        {field.type === "integer" && <Form.Control
                            type="text"
                            value={form[field.name] || ''}
                            onChange={onChange(field.name)}
                            required={field.required}
                        />}
                        {field.type === "select" && field.multiple &&
                            <Multiselect
                                className="multi-select--fullWidth"
                                options={field.options} // Options to display in the dropdown
                                selectedValues={form[field.name]} // Preselected value to persist in dropdown
                                onSelect={(selectedList) => {
                                  form[field.name] = selectedList;
                                }}
                                onRemove={(selectedList) => {
                                  form[field.name] = selectedList;
                                }}
                                avoidHighlightFirstOption={true}
                                displayValue="title"
                            />
                        }
                        {field.type === "select" && !field.multiple &&

                            <Multiselect
                                options={field.options} // Options to display in the dropdown
                                selectedValues={[
                                  {
                                    id: (form[field.name]) ? form[field.name].id : '',
                                    title: (form[field.name]) ? form[field.name].title : '',
                                  }
                                ]} // Preselected value to persist in dropdown
                                singleSelect={true}
                                onSelect={(selectedList) => {
                                  form[field.name] = selectedList[0];
                                }}
                                avoidHighlightFirstOption={true}
                                displayValue="title"
                            />
                        }
                        <FormText id="component-helper-text">{field.description || ''}</FormText>
                      </Col>
                    </Form.Group>
                ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit">Save </Button>
                    <Button className="pull-right mr-2" variant="light"
                            onClick={handleCancel}>Cancel</Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
  users: PropTypes.array
};

const mapStateToProps = ({ priceRules: { detailedPriceRule: { data, isProcessing } }, users }) => ({
  data,
  isProcessing,
  usersData: users.data
});

export default connect(mapStateToProps)(DetailsPage);
