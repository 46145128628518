import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormText, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {useHistory /* useParams */} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {Paper} from "@material-ui/core";

import {ROUTES} from "../../../common/constants/routes/routes";
import {API_URLS} from '../../../common/constants/api/apiConstants';
import initApi from '../../../crud/apiConfig/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DetailsPage = (props) => {
    const history = useHistory();

    // const {id} = useParams();
    const {isProcessing} = props;
    const [form, setForm] = useState({});
    const [validated, setValidated] = useState(false);

    const fields = [
        {title: 'Threading costs', name: 'threadingCosts', type: 'float', required: true},
        {title: 'Minimal product costs', name: 'minimalProductCosts', type: 'float', required: true},
    ]

    const getSettings = async () => {
        let settings = await initApi().get(API_URLS.GET_APP_SETTINGS);
        let newSettings = {
            id: settings.data.id,
            minimalProductCosts: settings.data.minimal_product_costs,
            threadingCosts: settings.data.threading_costs,
        }
        setForm(newSettings);
    }

    const updateSettings = async (payload) => {
        await initApi().post(API_URLS.GET_APP_SETTINGS, payload);
        toast.success("Settings saved", {
            position: 'bottom-right',
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }

    useEffect(() => {
        getSettings()
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        const payload = {};
        fields.forEach(field => {

            switch (field.type) {
                case 'integer':
                    payload[camelToSnakeCase(field.name)] = parseInt(form[field.name]);
                    break;

                case 'float':
                    payload[camelToSnakeCase(field.name)] = parseFloat(form[field.name]);
                    break;

                case 'select':
                    if (field.multiple) {
                        let payloadSelect = [];

                        form[field.name].forEach((item) => {
                            payloadSelect.push({id: item.id});
                        });
                        payload[field.name] = payloadSelect;

                    }
                    break;

                default:
                    payload[camelToSnakeCase(field.name)] = form[field.name];
            }
        })

        updateSettings(payload);
    }
    const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

    const handleCancel = () => {
        history.push(ROUTES.HOURLY_RATES);
    }

    const onChange = field => event => {
        setForm({...form, [field]: event.target.value});
    }
    const onChangeCheckbox = (field, event) => {
        setForm({...form, [field]: event.target.checked});
    }

    return isProcessing ? 'Loading...' : (
        <>
            <ToastContainer/>


            <div className="row">
                <div className="col-md-12">
                    <div className="kt-section">
                        <div className="kt-section__content">
                            <Paper className="p-5">
                                <h1 className="kt-invoice__title mb-5">Settings</h1>

                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                >
                                    {fields.map(field => (
                                        <Form.Group key={field.name} as={Row} controlId="">
                                            <Form.Label column sm={2}>{field.title}</Form.Label>
                                            <Col sm={10}>
                                                {field.type === "text" && <Form.Control
                                                    type="text"
                                                    defaultValue={form[field.name] || ''}
                                                    onChange={onChange(field.name)}
                                                    required={field.required}
                                                />}
                                                {field.type === "float" && <Form.Control
                                                    type="number"
                                                    value={form[field.name] || ''}
                                                    onChange={onChange(field.name)}
                                                    required={field.required}
                                                />}
                                                {field.type === "checkbox" && <Form.Check
                                                    type="checkbox"
                                                    checked={form[field.name]}
                                                    onChange={(evt) => onChangeCheckbox(field.name, evt)}
                                                    required={field.required}
                                                />}
                                                {field.type === "select" && <Multiselect
                                                    className="multi-select--fullWidth"
                                                    options={field.options} // Options to display in the dropdown
                                                    selectedValues={form[field.name]} // Preselected value to persist in dropdown
                                                    onSelect={(selectedList) => {
                                                        form[field.name] = selectedList;
                                                    }}
                                                    onRemove={(selectedList) => {
                                                        form[field.name] = selectedList;
                                                    }}
                                                    displayValue="name"
                                                />
                                                }
                                                <FormText id="component-helper-text">{field.description || ''}</FormText>
                                            </Col>
                                        </Form.Group>
                                    ))}
                                    <Row>
                                        <Col sm={12}>
                                            <Button className="pull-right" type="submit">Save </Button>
                                            <Button className="pull-right mr-2" variant="light" onClick={handleCancel}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

DetailsPage.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    data: PropTypes.object,
};

const mapStateToProps = ({hourlyRates: {detailedHourlyRate: {data, isProcessing}}, countries}) => ({
    data,
    isProcessing,
    countriesData: countries.data,
});

export default connect(mapStateToProps)(DetailsPage);
