import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  Portlet,
  PortletBody,
  PortletFooter,
} from '../../../partials/content/Portlet';
import { Delivery, Overview, Payment } from './partials';
import Address from './partials/Address';
import { getUser } from '../../../store/users/actions';
import './styles.css';
import { getShippingCosts } from '../../../store/shippingCosts/actions';
import { checkoutActions } from '../../../store/ducks/checkout.duck';
import { authActions } from '../../../store/ducks/auth.duck';
import { getDeliveryDays } from '../../../store/deliveryDays/actions';
import { getPaymentTypes } from '../../../store/paymentTypes/actions';
import { getVats } from '../../../store/vats/actions';
import { INITIAL_ADDRESS } from '../../../common/constants/constants';

import {
  arraysAreEqual,
  objectsAreEqual,
  createAddressWithType
} from '../../../../_metronic/utils/utils';

import { API_URLS } from '../../../common/constants/api/apiConstants';
import initApi from '../../../crud/apiConfig/apiConfig';

const CheckoutPage = (props) => {
  const {
    match: { params },
    id,
    user,
    getCheckout,
    putCheckout,
    getDeliveryDays,
    getPaymentTypes,
    getCountries,
    getVats,
    // getShippingCosts,
    shippingCostsList,
    setShippingAddressValue,
    setBillingAddressValue,
    products,
    isProcessing,
    discount,
    setValue,
    setPaymentType,
    shipping_costs,
    startup_costs,
    deliveryDays,
    paymentTypes,
    shipping_type,
    price,
    order,
    vat,
    location,
    proceedToPayment,
    saveAsQuote,
    delivery_day,
    delivery_days,
    reference,
    history,
    payment_type,
  } = props;
  const intl = useIntl();
  const [userID, setUserID] = useState(null);
  const dispatch = useDispatch();
  
  const [errors, setErrors] = useState({
    shipping: {
      first_name: false,
      last_name: false,
      address: false,
      house_number: false,
      postal_code: false,
      tax_number_validated: false,
      tax_number: false,
      phone_number: false,
      city: false,
    },
    billing: {
      first_name: false,
      last_name: false,
      address: false,
      house_number: false,
      postal_code: false,
      tax_number_validated: false,
      tax_number: false,
      phone_number: false,
      city: false,
    },
    payment_type: false,
  });

  const [addresses, setAddresses] = useState({
    billing: { ...INITIAL_ADDRESS },
    shipping: { ...INITIAL_ADDRESS },
  });
  const [isBilling, setIsBilling] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [password, setPassword] = useState('');
  const [totalProductWeight, setTotalProductWeight] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [oldProjectData, setOldProjectData] = useState();

  const urlSearchParams = new URLSearchParams(window.location.search);
  let oldProjectID = urlSearchParams.get('old_project_id');

  const isMounted = useRef(false);
  const paramsID = useRef();

  useEffect(() => {
    isMounted.current = true;
    getCountries();
    getVats();
    getPaymentTypes();
    // getShippingCosts();
    if (location.status == 'failed' || location.status == 'canceled') {
      this.handleOpen();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!params.id) {
      return;
    }

    paramsID.current = params.id;
    const id = Number(oldProjectID ? oldProjectID : paramsID.current);

    if (isMounted.current) {
      putCheckout({
        id,
        successCallBack: (response) => {
          setOldProjectData(response?.data);
          getCheckout(response?.data?.id);
          getDeliveryDays(response?.data?.id);
        },
      });
    }
  }, [params?.id, isMounted]);

  useEffect(() => {
    user?.id && setUserID(user?.id);
    user?.addresses && setAddresses(user?.addresses);
  }, [user?.id, user?.addresses]);

  useEffect(() => {
    if(userID) {
      dispatch(getUser(userID));
    }
  }, [userID]);

  useEffect(() => {
    let productTotalPrice = 0;
    let productTotalWeight = 0;

    products.forEach((product) => {
      productTotalPrice += product.price;
      productTotalWeight += product.weight;
    });

    setTotalProductPrice(productTotalPrice);
    setTotalProductWeight(productTotalWeight);
  }, [products]);

  useEffect(() => {
    if (!isBilling) {
      if (order.billing_address?.address) {
        order.billing_address.address = null;
      }
    }
  }, [isBilling]);

  useEffect(() => {
    if (order.id) {
      if (order.billing_address?.address) {
        setIsBilling(order.billing_address.address != null);
      }
    }
  }, [order.billing_address]);

  const getInitialValues = (data) => {
    const { order, delivery_days, products } = data;
    const mapProduct = ({ name, quantity }) => ({
      productName: name,
      productQuantity: quantity,
    });

    return {
      paymentType: order?.payment_type,
      deliveryDays: delivery_days,
      products: products.map(mapProduct),
      shippingAddress: order?.shipping_address || addresses.shipping,
      billingAddress: order?.billing_address || addresses.billing,
    };
  };

  const initialValues = oldProjectData && getInitialValues(oldProjectData);

  const isOrderDataChanged = (changedValues, initialValues) => {
    const {
      paymentType,
      deliveryDays,
      products,
      shippingAddress,
      billingAddress,
    } = changedValues;

    const mapProduct = ({ name, quantity }) => ({
      productName: name,
      productQuantity: quantity,
    });

    return (
      initialValues.paymentType !== paymentType ||
      initialValues.deliveryDays !== deliveryDays ||
      !arraysAreEqual(initialValues.products, products.map(mapProduct)) ||
      !objectsAreEqual(initialValues.shippingAddress, shippingAddress) ||
      !objectsAreEqual(initialValues.billingAddress, billingAddress)
    );
  };

  const handlePutCheckout = (data) => {
    const changedValues = {
      paymentType: data?.payment_type || order?.payment_type,
      deliveryDays: data?.delivery_days || delivery_days,
      products: data?.products || products,
      shippingAddress: data?.shipping_address || order?.shipping_address,
      billingAddress: data?.billing_address || order?.billing_address,
    };

    const isChanged =
      initialValues &&
      changedValues &&
      isOrderDataChanged(changedValues, initialValues);

    if (oldProjectID) {
      data.is_change = isChanged;
      data.old_project_id = oldProjectID;

      const updatedProducts = { ...data.products };

      if (data.products && !isChanged) {
        Object.keys(updatedProducts).forEach((key) => {
          if (updatedProducts[key] === 'material') {
            delete updatedProducts[key];
          }
        });

        data.products = updatedProducts;
      }
    }

    const id = Number(
      oldProjectID && !isChanged ? oldProjectID : paramsID.current
    );

    if (!userID) {
      let newUserData = {};
      const excludedFields = ['company_name', 'tax_number', 'tax_number_valid', 'ext'];

      if (!isBilling) {
        const shippingAddress = createAddressWithType(data.shipping_address, 1, excludedFields);
        if (shippingAddress) {
          newUserData.shipping_address = shippingAddress;
        }
      } else {
        const billingAddress = createAddressWithType(data.billing_address, 2, excludedFields);
        if (billingAddress) {
          newUserData.billing_address = billingAddress;
        }
      }

      newUserData.password = password || '';
      newUserData.reg_email = regEmail || '';

      initApi()
        .post(API_URLS.GET_USERS, newUserData)
        .then((response) => {
          setUserID(response?.data?.id);
          setAddresses(response?.data?.addresses);

          // eslint-disable-next-line no-restricted-globals
          const idString = window.location.pathname
            .split('/')
            .filter((part) => part !== '')
            .pop();

          const id = isNaN(Number(idString)) ? null : Number(idString);

          const shippingAddress = response?.data?.addresses.find((item) => item?.type?.title === 'Shipping') || addresses.shipping;
          const billingAddress = response?.data?.addresses.find((item) => item?.type?.title === 'Billing') || addresses.billing;

          if (!isBilling) {
            data.shipping_address = shippingAddress;
          } else {
            data.billing_address = billingAddress;
          }

          putCheckout({ data, id });
        })
        .catch((error) => {
          toast.error(
            error?.response?.status == 409 || error?.response?.data?.code == 409
              ? intl.formatMessage({ id: 'ERROR.EMAIL_IS_ALREADY_EXIST' })
              : error?.response?.data?.error ||
                  'Please ensure that you have filled in your email, password, and address details before completing your experience...',
            {
              position: 'bottom-right',
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
        });
    } else {
      putCheckout({
        data,
        id,
        successCallBack: (response) => {
          if (oldProjectID && !isChanged) {
            setPaymentType(oldProjectData?.order?.payment_type, 'payment_type');
            setValue(oldProjectData?.products, 'products');
            setValue(oldProjectData?.delivery_days, 'delivery_day');
            setValue(oldProjectData?.shipping_costs, 'shipping_costs');
            setValue(oldProjectData?.price, 'price');
            setValue(oldProjectData?.single_price, 'single_price');
            setValue(oldProjectData?.startup_costs, 'startup_costs');
            setValue(oldProjectData?.vat, 'vat');
            getDeliveryDays(oldProjectID);
          } else {
            setValue(response?.data?.products, 'products');
            setValue(response?.data?.delivery_days, 'delivery_day');
            setPaymentType(response?.data?.order?.payment_type, 'payment_type');
            setValue(response?.data?.shipping_costs, 'shipping_costs');
            setValue(response?.data?.price, 'price');
            setValue(response?.data?.single_price, 'single_price');
            setValue(response?.data?.startup_costs, 'startup_costs');
            setValue(response?.data?.vat, 'vat');
            getDeliveryDays(response?.data?.id);
          }
        },
      });
    }
  
  };

  const checkOnAccountDisabled = () => {
    const shippingAddress = order?.shipping_address || addresses.shipping;
    const billingAddress = order?.billing_address || addresses.billing;

    if (!isBilling) {
      if (shippingAddress?.company_name?.length === 0) {
        return true;
      }
      if (
        shippingAddress?.company_name?.length > 0 &&
        shippingAddress?.tax_number?.length === 0
      ) {
        return true;
      }

      if (shippingAddress?.company_name !== null) {
        return (
          shippingAddress?.company_name?.length > 0 &&
          shippingAddress?.tax_number?.length > 0 &&
          errors.shipping.tax_number
        );
      }
    }

    if (isBilling) {
      if (billingAddress?.company_name?.length === 0) {
        return true;
      }
      if (
        billingAddress?.company_name?.length > 0 &&
        billingAddress?.tax_number?.length === 0
      ) {
        return true;
      }

      if (billingAddress?.company_name !== null) {
        return (
          billingAddress?.company_name?.length > 0 &&
          billingAddress?.tax_number?.length > 0 &&
          errors.billing.tax_number
        );
      }
    }
    return true;
  };

  const changeDeliveryDays = (delivery_day, productionTime = 0) => {
    setValue(productionTime, 'delivery_day');

    const data = {
      delivery_days: delivery_day.days,
      delivery_days_with_production_time:
        productionTime > delivery_day.days ? productionTime : delivery_day.days,
    };
    // data.shipping_address = order.shipping_address || addresses.shipping;
    // data.billing_address = order.billing_address || addresses.billing;

    handlePutCheckout(data);

    // getDeliveryDays(id);
  };

  const changePaymentType = (paymentType) => {
    setPaymentType(paymentType.value, 'payment_type');

    const data = {
      shipping_address: order.shipping_address,
      payment_type: paymentType.value,
    };
    if (isBilling) {
      data.billing_address = order.billing_address;
    }

    handlePutCheckout(data);
  };

  const onChangeQuantity = (productId, quantity) => {
    const newProductArray = [];

    if (quantity < 0) {
      return false;
    }

    products.forEach((product) => {
      if (product.id == productId) {
        let singlePrice = product.price / product.quantity;
        product.quantity = parseInt(quantity);
        product.price = singlePrice * product.quantity;
      }
    });

    setValue(products, 'products');
    const data = {
      products: products,
    };

    data?.products?.map((item) => {
      const obj = {
        productName: item.name,
        productQuantity: item.quantity,
      };

      newProductArray.push(obj);
    });

    // data.shipping_address = order.shipping_address;
    // data.billing_address = order.billing_address;

    handlePutCheckout(data);

    // getDeliveryDays(id);
  };

  const getShippingPrice = () => {
    let shippingPrice = 0;

    shippingCostsList.forEach((costType) => {
      if (
        costType.weightFrom <= totalProductWeight &&
        costType?.country?.id == order.shipping_address?.country?.id
      ) {
        shippingPrice = parseFloat(costType.price);
      }
    });

    products.forEach((product) => {
      if (
        product.width > 1500 ||
        product.height > 1500 ||
        (product.width + product.height) * 2 > 3000
      ) {
        shippingPrice = 85;
        if (
          order.shipping_address?.country?.id == 4 ||
          order.shipping_address?.country?.id == 5
        ) {
          shippingPrice = 95;
        }
      }
    });

    return shippingPrice.toFixed(2);
  };

  const onChangeShippingType = (shippingType) => {
    setValue(shippingType, 'shipping_type');
    const data = {
      shipping_type: shippingType,
    };
    data.shipping_address = order.shipping_address;
    data.billing_address = order.billing_address;

    const id = Number(oldProjectID ? oldProjectID : paramsID.current);

    putCheckout({ data, id });
  };

  const addAddress = (type) => {
    if (type == 'Shipping') {
      setShippingAddressValue('...', 'first_name');
      setShippingAddressValue('...', 'last_name');
    }
    if (type == 'Billing') {
      setBillingAddressValue('...', 'first_name');
      setBillingAddressValue('...', 'last_name');
    }
  };

  const updateTax = (address, type) => {
    const data = {
      use_current_user_address: false,
    };

    const isBilling = type === 'Billing';
    const addressType = isBilling ? 'billing_address' : 'shipping_address';
    data[addressType] = address[addressType];

    if (oldProjectID) {
      const currentAddress = data[addressType];

      const changedValues = {
        paymentType: order?.payment_type,
        deliveryDays: delivery_days,
        products: products,
        shippingAddress: isBilling ? order?.shipping_address : currentAddress,
        billingAddress: isBilling ? currentAddress : order?.billing_address,
      };

      const isChanged =
        initialValues &&
        changedValues &&
        isOrderDataChanged(changedValues, initialValues);

      data.is_change = isChanged;

      data.old_project_id = oldProjectID;
    }

    // data.shipping_address = addresses?.shipping || order?.shipping_address;
    // data.billing_address = addresses?.billing || order?.billing_address;
    handlePutCheckout(data);
  };

  const validateForm = async () => {
    let payment_type = order.payment_type;

    let errorFound = false;
    let newErrors = {
      shipping: {
        first_name: false,
        last_name: false,
        address: false,
        house_number: false,
        postal_code: false,
        country: false,
        tax_number_validated: false,
        tax_number: false,
        phone_number: false,
        city: false,
      },
      billing: {
        first_name: false,
        last_name: false,
        address: false,
        house_number: false,
        postal_code: false,
        country: false,
        tax_number_validated: false,
        tax_number: false,
        phone_number: false,
        city: false,
      },
      payment_type: false,
      regEmail: false,
      password: false,
    };
    if (!user) {
      if (!regEmail || regEmail.length == 0) {
        newErrors.regEmail = true;
        errorFound = true;
      }

      if (!password || password.length == 0) {
        newErrors.password = true;
        errorFound = true;
      }
    }
    if (
      !order.shipping_address?.first_name ||
      order.shipping_address?.first_name?.length == 0
    ) {
      newErrors.shipping.first_name = true;
      errorFound = true;
    }

    if (
      !order.shipping_address?.last_name ||
      order.shipping_address?.last_name?.length == 0
    ) {
      newErrors.shipping.last_name = true;
      errorFound = true;
    }

    if (
      !order.shipping_address?.address ||
      order.shipping_address?.address?.length == 0
    ) {
      newErrors.shipping.address = true;
      errorFound = true;
    }

    if (
      !order.shipping_address?.house_number ||
      order.shipping_address?.house_number?.length == 0
    ) {
      newErrors.shipping.house_number = true;
      errorFound = true;
    }

    if (
      !order.shipping_address?.postal_code ||
      order.shipping_address?.postal_code?.length == 0
    ) {
      newErrors.shipping.postal_code = true;
      errorFound = true;
    }

    if (
      !order.shipping_address?.city ||
      order.shipping_address?.city?.length == 0
    ) {
      newErrors.shipping.city = true;
      errorFound = true;
    }
    if (!order.shipping_address?.country) {
      newErrors.shipping.country = true;
      errorFound = true;
    }

    if (
      !order.shipping_address?.phone_number ||
      order.shipping_address?.phone_number?.length == 0
    ) {
      newErrors.shipping.phone_number = true;
      errorFound = true;
    }
    if (
      order.shipping_address?.company_name?.length > 0 &&
      order.shipping_address?.tax_number.length == 0
    ) {
      newErrors.shipping.tax_number = true;
      errorFound = true;
    }

    if (!payment_type || payment_type?.length == 0) {
      newErrors.payment_type = true;
      errorFound = true;
    }

    //
    if (isBilling) {
      if (
        !order.billing_address?.first_name ||
        order.billing_address?.first_name?.length == 0
      ) {
        newErrors.billing.first_name = true;
        errorFound = true;
      }

      if (
        !order.billing_address?.last_name ||
        order.billing_address?.last_name?.length == 0
      ) {
        newErrors.billing.last_name = true;
        errorFound = true;
      }

      if (
        !order.billing_address?.address ||
        order.billing_address?.address.length == 0
      ) {
        newErrors.billing.address = true;
        errorFound = true;
      }

      if (
        !order.billing_address?.house_number ||
        order.billing_address?.house_number?.length == 0
      ) {
        newErrors.billing.house_number = true;
        errorFound = true;
      }

      if (
        !order.billing_address?.postal_code ||
        order.billing_address?.postal_code?.length == 0
      ) {
        newErrors.billing.postal_code = true;
        errorFound = true;
      }

      if (
        !order.billing_address?.city ||
        order.billing_address?.city?.length == 0
      ) {
        newErrors.billing.city = true;
        errorFound = true;
      }
      if (
        !order.billing_address?.phone_number ||
        order.billing_address?.phone_number?.length == 0
      ) {
        newErrors.billing.phone_number = true;
        errorFound = true;
      }
      if (
        order.billing_address?.company_name?.length > 0 &&
        order.billing_address?.tax_number?.length == 0
      ) {
        newErrors.billing.tax_number = true;
        errorFound = true;
      }
    }

    if (
      order?.shipping_address?.company_name?.length > 0 &&
      order?.shipping_address?.tax_number?.length == 0
    ) {
      newErrors.shipping.tax_number = true;
      errorFound = true;
    }
    if (
      order?.billing_address?.company_name?.length > 0 &&
      !order?.billing_address?.country
    ) {
      newErrors.billing.country = true;
      errorFound = true;
    }

    setErrors(newErrors);

    return {
      errorFound,
      errors: newErrors,
    };
  };

  const validateSaveAsQuote = () => {
    const res1 = Object.keys(order.shipping_address).find((key) => {
      const value = order.shipping_address[key];

      if (key === 'ext') {
        return false;
      }
      return value === '' || value === null;
    });

    const res2 =
      order?.billing_address &&
      Object.keys(order.billing_address).find((key) => {
        const value = order.billing_address[key];

        if (key === 'ext') {
          return false;
        }
        return value === '' || value === null;
      });

    return isBilling ? res1 || res2 : res1;
  };

  const handleSaveOrder = async () => {
    // validateSaveAsQuote();
    let errorResult = await validateForm();

    if (errorResult.errorFound) {
      if (Object.values(errorResult.errors.shipping).includes(true)) {
        toast.error('There are errors in the shipping address', {
          position: 'bottom-right',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
      if (Object.values(errorResult.errors.billing).includes(true)) {
        toast.error('There are errors in the billing address', {
          position: 'bottom-right',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }

      return;
    }

    let payment_type = order.payment_type;

    const data = {
      shipping_address: order.shipping_address,
      use_current_user_address: false,
      payment_type,
      delivery_day,
      shipping_type,
      reference,
    };

    data.invite_email = inviteEmail;

    if (!user) {
      // data.password = password;
      // data.reg_email = regEmail;
    }

    if (isBilling) {
      data.billing_address = order.billing_address;
    }

    const changedValues = {
      paymentType: order?.payment_type,
      deliveryDays: delivery_days,
      products: products,
      shippingAddress: order?.shipping_address,
      billingAddress: order?.billing_address,
    };

    const initialValues = oldProjectData && getInitialValues(oldProjectData);

    const isChanged =
      initialValues && changedValues
        ? isOrderDataChanged(changedValues, initialValues)
        : true;

    if (!isChanged && oldProjectID) {
      data.is_change = isChanged;
      data.old_project_id = oldProjectID;
    }

    const id = Number(paramsID.current);
    proceedToPayment(data, id, history);
  };

  const handleSaveAsQuote = async () => {
    // validateSaveAsQuote();

    if (await validateForm()) {
      let payment_type = order.payment_type;

      if (!payment_type) {
        return;
      }

      const data = {
        shipping_address: order.shipping_address,
        use_current_user_address: false,
        payment_type,
        delivery_day,
        shipping_type,
        reference,
      };

      if (!user) {
        // data.password = password;
        // data.reg_email = regEmail;
      }

      data.invite_email = inviteEmail;

      if (isBilling) {
        data.billing_address = order.billing_address;
      }

      const changedValues = {
        paymentType: order?.payment_type,
        deliveryDays: delivery_days,
        products: products,
        shippingAddress: order?.shipping_address,
        billingAddress: order?.billing_address,
      };

      const initialValues = oldProjectData && getInitialValues(oldProjectData);

      const isChanged =
        initialValues && changedValues
          ? isOrderDataChanged(changedValues, initialValues)
          : true;

      data.is_change = isChanged;
      data.new_project_id = paramsID.current;

      if (!isChanged && oldProjectID) {
        data.old_project_id = oldProjectID;
      }

      const id = Number(paramsID.current);
      saveAsQuote(data, id, history);
    }
  };

  // const hasAddressError = (type) => {
  //   for (let [field, val] of Object.entries(errors[type])) {
  //     if (val === true) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const onChangeFoil = (event, product) => {
    let newProducts = products.map((currentProduct) => {
      if (currentProduct.id == product.id) {
        product.foil_removal = event.target.checked;
      }

      return product;
    });

    setValue(newProducts, 'products');

    const data = {
      products: newProducts,
    };

    const id = Number(oldProjectID ? oldProjectID : paramsID.current);

    putCheckout({ data, id });
  };

  const handleChangeAddress = (type, val, name) => {
    if (type == 'shipping') {
      setShippingAddressValue(val, name);
    }
    if (type == 'billing') {
      setBillingAddressValue(val, name);
    }
  };

  return isProcessing ? (
    'Loading...'
  ) : (
    <>
      <div id={'kt_subheader'} className={'subheader py-2 py-lg-4'}>
        <div
          className={
            'container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'
          }
        >
          <div className={'d-flex align-items-center flex-wrap mr-1'}>
            <div className={'d-flex align-items-baseline mr-5'}>
              <h5
                className={
                  'text-dark font-weight-bold my-2 mr-5 text-uppercase'
                }
              >
                <FormattedMessage id={'CHECKOUT.PRICE_CALCULATION'} />
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className={'kt-form kt-form--label-right'}>
        <Portlet className={'checkout-portlet'}>
          <PortletBody>
            {!user && (
              <Row className={'mb-5'}>
                <Col xs={6}>
                  <Row>
                    <Col>
                      <FormattedMessage id={'CHECKOUT.ACCOUNT_OPENING'} />
                    </Col>
                  </Row>

                  <Row className={'mt-4'}>
                    <Col as={'h6'} className={'font-weight-bold'}>
                      <FormattedMessage id={'CHECKOUT.EMAIL'} />
                    </Col>
                    <Col as={'h6'} className={'font-weight-bold'}>
                      <FormattedMessage id={'CHECKOUT.PASSWORD'} />
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col>
                      <Form.Control
                        value={regEmail}
                        className={
                          'w-100' + errors && errors.regEmail
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        type={'text'}
                        placeholder={intl.formatMessage({
                          id: 'CHECKOUT.EMAIL',
                        })}
                        onChange={(e) => {
                          setRegEmail(e.target.value, 'regEmail');
                        }}
                      ></Form.Control>
                    </Col>

                    <Col>
                      <Form.Control
                        value={password}
                        className={
                          'w-100' + errors && errors.password
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        type={'password'}
                        placeholder={intl.formatMessage({
                          id: 'CHECKOUT.PASSWORD',
                        })}
                        onChange={(e) => {
                          setPassword(e.target.value, 'password');
                        }}
                      ></Form.Control>
                    </Col>
                  </Row>

                  <Row className={'mt-4'}>
                    <Col>
                      <FormattedMessage id="AUTH.REGISTER.BY_REGISTERING" />
                      &nbsp;
                      <a
                        href={intl.formatMessage({ id: 'FOOTER.URL_TERMS' })}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="AUTH.REGISTER.TERMS" />
                      </a>
                      &nbsp;
                      <FormattedMessage id="AUTH.REGISTER.AND" />
                      &nbsp;
                      <a
                        href={intl.formatMessage({
                          id: 'FOOTER.PRIVACY_URL',
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="AUTH.REGISTER.PRIVACYSTATEMENT" />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row className={'mt-2'}>
              <Col xs={6}>
                <Address
                  user={user}
                  userId={userID}
                  regEmail={regEmail}
                  password={password}
                  updateTax={updateTax}
                  address={order.shipping_address || addresses.shipping}
                  addresses={addresses}
                  onChangeAddressValue={(val, name) => {
                    handleChangeAddress('shipping', val, name);
                  }}
                  // addAddress={addAddress}
                  addressType={'Shipping'}
                  countries={props.countries}
                  errors={errors}
                  setErrors={setErrors}
                  setAddresses={setAddresses}
                  isBilling={isBilling}
                  setIsBilling={setIsBilling}
                  order={order}
                />
                <Address
                  user={user}
                  userId={userID}
                  regEmail={regEmail}
                  password={password}
                  updateTax={updateTax}
                  address={order.billing_address  || addresses.billing}
                  addresses={addresses}
                  onChangeAddressValue={(val, name) => {
                    handleChangeAddress('billing', val, name);
                  }}
                  // addAddress={addAddress}
                  setAddresses={setAddresses}
                  addressType={'Billing'}
                  countries={props.countries}
                  errors={errors}
                  setErrors={setErrors}
                  isBilling={isBilling}
                  setIsBilling={setIsBilling}
                  order={order}
                />

                {!user && (
                  <>
                    <Row className={'mt-4'}>
                      <Col as={'h6'} className={'font-weight-bold'}>
                        <FormattedMessage id={'CHECKOUT.REFERENCE_OPTIONAL'} />
                      </Col>
                    </Row>
                    <Row className={'mb-5'}>
                      <Col>
                        <Form.Control
                          value={reference}
                          className={'w-100'}
                          type={'text'}
                          placeholder={intl.formatMessage({
                            id: 'CHECKOUT.REFERENCE',
                          })}
                          onChange={(e) => {
                            setValue(e.target.value, 'reference');
                          }}
                        ></Form.Control>
                      </Col>
                    </Row>
                  </>
                )}

                <Delivery
                  delivery_days={delivery_days || 14}
                  deliveryDays={deliveryDays}
                  country={order.shipping_address?.country}
                  products={products}
                  onChangeShippingType={onChangeShippingType}
                  changeDeliveryDays={changeDeliveryDays}
                  getShippingPrice={getShippingPrice}
                />

                <Payment
                  payment_type={payment_type || order?.payment_type || ''}
                  paymentTypes={paymentTypes}
                  onAccountDisabled={checkOnAccountDisabled}
                  changePaymentType={changePaymentType}
                />

                {errors.payment_type && (
                  <div className="row">
                    <div className={'col-md-12'}>
                      <Alert variant={'warning'}>
                        <FormattedMessage id="CHECKOUT.PLEASE_SELECT_PAYMENT_METHOD" />
                      </Alert>
                    </div>
                  </div>
                )}

                {user && (
                  <Row className={'mt-5'}>
                    <Col as={'h6'} className={'font-weight-bold'}>
                      <FormattedMessage id={'CHECKOUT.REFERENCE_OPTIONAL'} />
                    </Col>
                    {user?.admin && (
                      <Col as={'h6'} className={'font-weight-bold'}>
                        <FormattedMessage
                          id={
                            user?.admin
                              ? 'CHECKOUT.EMAIL_OPTIONAL'
                              : 'CHECKOUT.EMAIL'
                          }
                        />
                      </Col>
                    )}
                  </Row>
                )}

                {user && (
                  <Row className={'mt-2'}>
                    <Col>
                      <Form.Control
                        value={reference || ''}
                        className={'w-100'}
                        type={'text'}
                        placeholder={intl.formatMessage({
                          id: 'CHECKOUT.REFERENCE',
                        })}
                        onChange={(e) => {
                          setValue(e.target.value, 'reference');
                        }}
                      ></Form.Control>
                    </Col>

                    {user?.admin && (
                      <Col>
                        <Form.Control
                          value={inviteEmail}
                          className={
                            'w-100' + errors && errors.inviteEmail
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                          type={'text'}
                          placeholder={intl.formatMessage({
                            id: 'CHECKOUT.EMAIL',
                          })}
                          onChange={(e) => {
                            setInviteEmail(e.target.value, 'inviteEmail');
                          }}
                        ></Form.Control>
                      </Col>
                    )}
                  </Row>
                )}

                <Row className={'mt-5'}>
                  <PortletFooter className={'w-100'} />
                </Row>

                <Row className={'mr-0'}>
                  <Col>
                    <button
                      type={'button'}
                      className={'btn btn-block btn-bold btn-outline-brand'}
                      onClick={() => handleSaveAsQuote()}
                    >
                      <FormattedMessage id={'CHECKOUT.SAVE_AS_QUOTE'} />
                    </button>
                  </Col>
                  <Col>
                    <button
                      type={'button'}
                      className={'btn btn-block btn-brand btn-bold'}
                      onClick={() => handleSaveOrder()}
                      disabled={!userID}
                    >
                      <FormattedMessage id={'CHECKOUT.PLACE_ORDER'} />
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Overview
                  projectId={id}
                  products={products}
                  vat={vat}
                  price={price}
                  discount={discount}
                  shipping_costs={shipping_costs}
                  startup_costs={startup_costs}
                  shipping_type={shipping_type}
                  shippingAddress={order.shipping_address || addresses.shipping}
                  shippingCostsList={shippingCostsList}
                  onChangeQuantity={onChangeQuantity}
                  onChangeFoil={onChangeFoil}
                  totalProductPrice={totalProductPrice}
                />
              </Col>
            </Row>
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

CheckoutPage.propTypes = {
  setValue: PropTypes.func.isRequired,
  setPaymentType: PropTypes.func.isRequired,
  setShippingAddressValue: PropTypes.func.isRequired,
  setBillingAddressValue: PropTypes.func.isRequired,
  saveAsQuote: PropTypes.func.isRequired,
  proceedToPayment: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  putCheckout: PropTypes.func.isRequired,
  getShippingCosts: PropTypes.func.isRequired,
  getPaymentTypes: PropTypes.func.isRequired,
  getUserRequest: PropTypes.func.isRequired,
  getVats: PropTypes.func.isRequired,
  processPayment: PropTypes.func.isRequired,
  id: PropTypes.number,
  countries: PropTypes.array.isRequired,
  user: PropTypes.object,
  products: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  vat: PropTypes.number,
  order: PropTypes.object.isRequired,
  payment_type: PropTypes.string,
  vat_percentage: PropTypes.number,
  delivery_day: PropTypes.number.isRequired,
  shipping_costs: PropTypes.number,
  shipping_type: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  shippingCostsList: PropTypes.array,
  isProcessing: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = ({
  // shippingAddress,
  checkout: {
    form,
    form: { order, payment_info, countries },
    isProcessing,
  },
  deliveryDays,
  paymentTypes,
  shippingCosts,
  vats,
  auth: { user },
  users: {},
}) => ({
  ...form,
  order,
  shippingCostsList: shippingCosts.data,
  vats: vats.data,
  isProcessing,
  addresses: order ? [order.shipping_address, order.billing_address ?? {...INITIAL_ADDRESS}] : [],
  payment_info,
  countries,
  deliveryDays: deliveryDays.data,
  paymentTypes: paymentTypes.data,
  user,
});

const mapDispatchToProps = {
  checkAuth: authActions.checkAuth,
  getUserRequest: (id) => getUser(id),
  getShippingCosts: getShippingCosts,
  setValue: (value, name) =>
    checkoutActions.setValue({
      value,
      name,
    }),
  setPaymentType: (value, name) =>
    checkoutActions.setPaymentType({
      value,
      name,
    }),
  getDeliveryDays: (id) => getDeliveryDays(id),
  getPaymentTypes: getPaymentTypes,
  setShippingAddressValue: (value, name) =>
    checkoutActions.setShippingAddressValue({
      value,
      name,
    }),
  setBillingAddressValue: (value, name) =>
    checkoutActions.setBillingAddressValue({
      value,
      name,
    }),
  fulfillForm: checkoutActions.fulfillForm,
  saveAsQuote: (data, projectId, history, params) =>
    checkoutActions.saveAsQuoteRequest({
      data,
      projectId,
      params,
      history,
    }),
  getCountries: checkoutActions.getCountriesRequest,
  getVats: getVats,
  proceedToPayment: (data, projectId, history, params) =>
    checkoutActions.proceedToPaymentRequest({
      data,
      projectId,
      params,
      history,
    }),
  saveOrder: (data, projectId, history, params) =>
    checkoutActions.saveOrder({
      data,
      projectId,
      params,
      history,
    }),
  getCheckout: checkoutActions.getCheckoutRequest,
  putCheckout: (data, id, params, successCallBack) =>
    checkoutActions.putCheckoutRequest({
      data,
      id,
      params,
      successCallBack,
    }),
  processPayment: (data, history) =>
    checkoutActions.processPaymentRequest({
      data,
      history,
    }),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutPage))
);
