import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import EastIcon from '@mui/icons-material/East';

const ConfiguratorDropzone = ({
  importDxfFiles,
  loading,
  uploadedSvgs,
  user,
  isProcessing,
  isMainPage,
}) => {
  const intl = useIntl();

  return (
    <Dropzone
      accept={['.dxf', '.DXF', '.step', '.STEP', '.stp', '.STP']}
      className="upload-container"
      onDrop={importDxfFiles}
      disabled={loading.status || isProcessing}
      minSize={0}
      maxSize={2000000}
    >
      {({ getRootProps, getInputProps, rejectedFiles }) => {
        const isFileTooLarge =
          rejectedFiles.length > 0 && rejectedFiles[0].size > 1448576;

        return (
          <div className={'dropzone-container '}>
            <div {...getRootProps({ className: 'dropzone-area' })}>
              <input {...getInputProps()} />

              {loading.status || isProcessing ? (
                <FormattedMessage id="ORDER.LOADING.CONFIGURATOR.SMALL" />
              ) : (
                <>
                  <i
                    className={
                      'flaticon2-delivery-package upload-image ' +
                      ((!user || isMainPage) && 'red-icon')
                    }
                  />
                  <span className={'upload-msg'}>
                    {!user ? (
                      <FormattedMessage id="CONFIGURATOR.DRAG_AND_DROP_DXF_AND_2D_STEP_FILES" />
                    ) : (
                      <FormattedMessage id="CONFIGURATOR.DRAG_AND_DROP_DXF_FILES" />
                    )}
                  </span>
                  <button
                    className={
                      (uploadedSvgs.length == 0 && !isMainPage
                        ? 'btn btn-primary btn-md'
                        : 'btn-outline-primary btn btn-md') +
                      (uploadedSvgs.length == 0 &&
                        isMainPage &&
                        ' btn-outline-primary btn btn-md bp-button ')
                    }
                  >
                    {intl.formatMessage({ id: 'Selecteer bestanden' })}
                    {isMainPage && (
                      <span className="icon-right">
                        <EastIcon />
                      </span>
                    )}
                  </button>
                </>
              )}

              <span style={{ padding: "20px 0", display: "inline-block", color: "#a1a8c3" }}>
              <FormattedMessage 
              id="CONFIGURATOR.NO_DXF.TEXT"
              values={{
                cadLink: (
                  <Link
                    to={!user ? "/auth/login" : "/cutwisecad"}
                    onClick={(event) => event.stopPropagation()}
                  >
                    CutWise CAD
                  </Link>
                ),
              }}
            />
              </span>
            </div>

            {isFileTooLarge && (
              <div className="text-danger mt-2">
                <FormattedMessage id="CONFIGURATOR.FILE_IS_TOO_LARGE" />
              </div>
            )}
          </div>
        );
      }}
    </Dropzone>
  );
};

const mapStateToProps = ({
  auth: { user },
  draw: { uploadedSvgs, loading, isProcessing },
}) => ({
  user,
  uploadedSvgs,
  loading,
  isProcessing,
});

export default injectIntl(connect(mapStateToProps)(ConfiguratorDropzone));
