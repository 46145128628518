import { handleActions } from "redux-actions";
import {
  getUsers,
  getUsersSuccess,
  getUsersError,
  getUsersPage,
  getUsersPageSuccess,
  getUsersPageError,
  getUser,
  getUserSuccess,
  getUserError,
  updateUser,
  updateUserSuccess,
  updateUserError,
  deleteUser,
  deleteUserSuccess,
  deleteUserError,
} from "./actions";

const initialState = {
  isProcessing: false,
  data: [],
  error: "",
  pagination: {
    per_page: 10,
    current_page: 1,
    count: 0
  },
  detailedUser: {
    isProcessing: false,
    data: null,
    status: {
      isProcessing: false
    }
  },
};

const handlers = {
  [getUsers]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getUsersSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getUsersError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getUsersPage]: (state) => ({
    ...state,
    isProcessing: true,
  }),
  [getUsersPageSuccess]: (state, action) => ({
    ...state,
    isProcessing: false,
    data: action.payload,
    // data: payload.quotes,
    pagination: {
      ...state.pagination,
      count: (action.payload && action.payload.length) || 0
    }
  }),
  [getUsersPageError]: (state, action) => ({
    ...state,
    isProcessing: false,
    error: action.payload
  }),
  [getUser]: (state) => ({
    ...state,
    detailedUser: {
      ...state.detailedUser,
      isProcessing: true
    }
  }),
  [getUserSuccess]: (state, action) => ({
    ...state,
    detailedUser: {
      ...state.detailedUser,
      isProcessing: false,
      data: action.payload
    }
  }),
  [getUserError]: (state, action) => ({
    ...state,
    detailedUser: {
      ...state.detailedUser,
      isProcessing: false,
      error: action.payload
    }
  }),
  [updateUser]: (state) => ({
    ...state,
    detailedUser: {
      ...state.detailedUser,
      isProcessing: true,
    }
  }),
  [updateUserSuccess]: (state) => ({
    ...state,
    detailedUser: {
      ...initialState.detailedUser,
      isProcessing: false,
    }
  }),
  [updateUserError]: (state, action) => ({
    ...state,
    detailedUser: {
      ...state.detailedUser,
      isProcessing: false,
      status: {
        ...state.detailedUser.status,
        error: action.payload
      }
    }
  }),
  [deleteUser]: (state) => ({
    ...state,
  }),
  [deleteUserSuccess]: (state) => ({
    ...state,
  }),
  [deleteUserError]: (state) => ({
    ...state,
  }),
};

export default handleActions(handlers, initialState);
