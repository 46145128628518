import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Col, Button, FormText } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { Paper } from '@material-ui/core';
import {
  updateHourlyRate,
  getHourlyRate,
} from '../../../store/hourlyRates/actions';
import { getCountries } from '../../../store/countries/actions';
import { ROUTES } from '../../../common/constants/routes/routes';

const DetailsPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isProcessing, countriesData } = props;
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  const [countries, setCountries] = useState([]);
  // const [selectedCountries, setSelectedCountries] = useState([]);

  const fields = [
    {
      title: 'Delivery Days',
      name: 'deliveryDays',
      type: 'integer',
      required: true,
    },
    { title: 'Rate', name: 'rate', type: 'text', required: true },
    {
      title: 'Minimum price',
      name: 'minimumPrice',
      type: 'text',
      required: true,
    },
    {
      title: 'Startup costs',
      name: 'startupCosts',
      type: 'text',
      required: true,
    },
    {
      title: 'Block orders when production time',
      name: 'blockOrdersAmount',
      type: 'text',
      required: true,
    },
    {
      title: 'Countries',
      name: 'countries',
      type: 'select',
      required: false,
      multiple: true,
      options: countries,
    },
    { title: 'Block', name: 'isBlocked', type: 'checkbox', required: true },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getHourlyRate(id));
    }
  }, []);

  useEffect(() => {
    dispatch(getCountries());
  }, []); // eslint-disable-line

  useEffect(() => {
    setCountries(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (data && id) {
      setForm(data);
    } else {
      setForm({ countries: [] });
    }
  }, [data]); // eslint-disable-line

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const payload = {};
    fields.forEach((field) => {
      switch (field.type) {
        case 'integer':
          payload[camelToSnakeCase(field.name)] = parseInt(form[field.name]);
          break;

        case 'float':
          payload[camelToSnakeCase(field.name)] = parseFloat(form[field.name]);
          break;

        case 'select':
          if (field.multiple) {
            let payloadSelect = [];

            form[field.name].forEach((item) => {
              payloadSelect.push({ id: item.id });
            });
            payload[field.name] = payloadSelect;
          }
          break;

        default:
          payload[camelToSnakeCase(field.name)] = form[field.name];
      }
    });

    dispatch(
      updateHourlyRate({
        id: id,
        data: payload,
        successCallBack: () => {
          history.push(ROUTES.HOURLY_RATES);
        },
      })
    );
  };
  const camelToSnakeCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

  const handleCancel = () => {
    history.push(ROUTES.HOURLY_RATES);
  };

  const onChange = (field) => (event) => {
    setForm({ ...form, [field]: event.target.value });
  };
  const onChangeCheckbox = (field, event) => {
    setForm({ ...form, [field]: event.target.checked });
  };

  return isProcessing ? (
    'Loading...'
  ) : (
    <div className="row">
      <div className="col-md-12">
        <div className="kt-section">
          <div className="kt-section__content">
            <Paper className="p-5">
              <h1 className="kt-invoice__title mb-5">
                HourlyRate {id ? 'Edit' : 'Add'}
              </h1>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {fields.map((field) => (
                  <Form.Group key={field.name} as={Row} controlId="">
                    <Form.Label column sm={2}>
                      {field.title}
                    </Form.Label>
                    <Col sm={10}>
                      {field.type === 'text' && (
                        <Form.Control
                          type="text"
                          defaultValue={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      )}
                      {field.type === 'integer' && (
                        <Form.Control
                          type="text"
                          value={form[field.name] || ''}
                          onChange={onChange(field.name)}
                          required={field.required}
                        />
                      )}
                      {field.type === 'checkbox' && (
                        <Form.Check
                          type="checkbox"
                          checked={form[field.name]}
                          onChange={(evt) => onChangeCheckbox(field.name, evt)}
                          required={field.required}
                        />
                      )}
                      {field.type === 'select' && (
                        <Multiselect
                          className="multi-select--fullWidth"
                          options={field.options} // Options to display in the dropdown
                          selectedValues={form[field.name]} // Preselected value to persist in dropdown
                          onSelect={(selectedList) => {
                            form[field.name] = selectedList;
                          }}
                          onRemove={(selectedList) => {
                            form[field.name] = selectedList;
                          }}
                          avoidHighlightFirstOption={true}
                          displayValue="name"
                        />
                      )}
                      <FormText id="component-helper-text">
                        {field.description || ''}
                      </FormText>
                    </Col>
                  </Form.Group>
                ))}
                <Row>
                  <Col sm={12}>
                    <Button className="pull-right" type="submit">
                      Save{' '}
                    </Button>
                    <Button
                      className="pull-right mr-2"
                      variant="light"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

const mapStateToProps = ({
  hourlyRates: {
    detailedHourlyRate: { data, isProcessing },
  },
  countries,
}) => ({
  data,
  isProcessing,
  countriesData: countries.data,
});

export default connect(mapStateToProps)(DetailsPage);
