import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { metronic } from '../../../_metronic';
import { API_URLS } from '../../common/constants/api/apiConstants';
import initApi from '../../crud/apiConfig/apiConfig';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { AreaGraph } from '../../partials/content/Graphs/AreaGraph';
import { VerticalBarGraph } from '../../partials/content/Graphs/VerticalBarGraph';
import { StatsDisplay } from '../../partials/content/Stats/StatsDisplay';
import { TopListDisplay } from '../../partials/content/Stats/TopListDisplay';
import {
  COUNTRY_LIST,
  MATERIAL_CATEGORIES,
} from '../../common/constants/constants';

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const [productionData, setProductionData] = useState([]);
  const isMounted = useRef(false);

  let dateFrom = new Date();
  dateFrom.setFullYear(dateFrom.getFullYear() - 1);
  const [dateValues, setDateValues] = useState([null, null]);

  useEffect(() => {
    isMounted.current = true;

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getProductionData();
    }
  }, [isMounted]);

  const formatMoney = (value) => {
    if (value) {
      value = Number(value).toFixed(2);
    }
    return value
      ? '€ ' +
          value
            .toString()
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : '€ 0';
  };

  const formatPercentage = (value) => {
    if (value) {
      value = Number(value).toFixed(2);
    }
    return value
      ? value
          .toString()
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '%'
      : '0%';
  };

  const categoryMap = MATERIAL_CATEGORIES.reduce((acc, { id, title }) => {
    acc[id] = title;
    return acc;
  }, {});

  const countryMap = COUNTRY_LIST.reduce((acc, { id, title }) => {
    acc[id] = title;
    return acc;
  }, {});

  const changeKeys = (obj, keyMapping) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      acc[keyMapping[key] || key] = value;
      return acc;
    }, {});

  const keyMapping = {
    material_total_price: 'label',
    category: 'strong',
  };

  const keyMappingRegUsers = {
    count_of_users: 'label',
    count_of_users_by_order: 'enumerate',
    country: 'strong',
    count_of_orders: 'count',
    orders_total_price: 'price',
  };

  const keyMappingByMilling = {
    projects: 'label',
    product_type: 'strong',
  };

  const keyMappingProjects = {
    orders: 'Orders',
    discounts: 'Discounts',
    shipping_costs: 'Shipping Costs',
    startup_costs: 'Startup Costs',
  };

  const updatedOrderPriceByCategory =
    dashboardData?.ordersInfoData?.total_price_materials?.data_by_category &&
    dashboardData?.ordersInfoData?.total_price_materials?.data_by_category.map((order) => {
      const updatedOrder = changeKeys(order, keyMapping);
      updatedOrder.strong = categoryMap[order.category] || order.category;
      updatedOrder.label = formatMoney(updatedOrder.label);

      return updatedOrder;
    });

  let updatedOrderPriceByMilled =
    dashboardData?.materialMilledData?.material_price_total_to_be_milled?.projectPriceByMaterial &&
    dashboardData?.materialMilledData?.material_price_total_to_be_milled?.projectPriceByMaterial.map((order) => {
      const updatedOrder = changeKeys(order, keyMappingByMilling);
      updatedOrder.strong =
        categoryMap[order.product_type] || order.product_type;

      updatedOrder.strong =
        updatedOrder.strong === 'laser cutting' ? ' Lasersnijden' : 'Frezen';
      updatedOrder.label = formatMoney(updatedOrder.label);

      return updatedOrder;
    });

  updatedOrderPriceByMilled?.push({
    strong: 'Total',
    label: formatMoney(dashboardData?.materialMilledData?.material_price_total_to_be_milled?.totalProductPrice[0].products),
  });

  const countryData = {
    Netherlands: {
      total: 0,
      count: 0,
      enumerate: 0,
      price: 0,
      revenue: 0,
      aliases: ['Netherlands', 'The Netherlands', '1'],
      countryCode: '1',
    },
    Germany: {
      total: 0,
      count: 0,
      enumerate: 0,
      price: 0,
      revenue: 0,
      aliases: ['Germany', '2'],
      countryCode: '2',
    },
    Belgium: {
      total: 0,
      count: 0,
      enumerate: 0,
      price: 0,
      revenue: 0,
      aliases: ['Belgium', '3'],
      countryCode: '3',
    },
    // Add more countries as needed
  };

  let combinedData = {};

  dashboardData?.userCountriesOrdersData?.registered_user_countries_and_orders.forEach((item) => {
    const country = item.country;

    if (!combinedData[country]) {
      combinedData[country] = {
        country,
        count_of_users: 0,
        count_of_users_by_order: 0,
        count_of_orders: 0,
        orders_total_price: 0,
        orders_total_price_by_country: 0,
      };
    }
    combinedData[country].count_of_users = 0;
    combinedData[country].count_of_users_by_order +=
      item.count_of_users_by_order;
    combinedData[country].count_of_orders += item.count_of_orders;
    combinedData[country].orders_total_price += item.orders_total_price
      ? Number(item.orders_total_price)
      : 0;
  });

  dashboardData?.userCountriesData?.registered_user_countries.forEach((item) => {
    const country = item.country;

    if (!combinedData[country]) {
      combinedData[country] = {
        country,
        count_of_users: 0,
        count_of_users_by_order: 0,
        count_of_orders: 0,
        orders_total_price: 0,
        orders_total_price_by_country: 0,
      };
    }
    combinedData[country].count_of_users += item.users_count;
  });

  dashboardData?.countriesOrderData?.get_countries_and_orders_total.forEach((item) => {
    const country = item.country;

    if (!combinedData[country]) {
      combinedData[country] = {
        country,
        count_of_users: 0,
        count_of_users_by_order: 0,
        count_of_orders: 0,
        orders_total_price: 0,
        orders_total_price_by_country: 0,
      };
    }
    combinedData[country].orders_total_price_by_country += item.orders_total_price;
  });

  let combinedList = [];

  /* eslint-disable no-unused-vars */
  Object.entries(combinedData).forEach(([key, value]) => {
    for (const country in countryData) {
      if (countryData[country].aliases.includes(value.country)) {
        countryData[country].total += value.count_of_users;
        countryData[country].enumerate += value.count_of_users_by_order;
        countryData[country].count += value.count_of_orders;
        countryData[country].price +=
          value.orders_total_price === null
            ? 0
            : Number(value.orders_total_price);
        countryData[country].revenue +=
          value.orders_total_price_by_country === null
            ? 0
            : Number(value.orders_total_price_by_country);
        return;
      }
    }

    combinedList.push(value);
  });

  for (const country in countryData) {
    const { total, enumerate, count, price, revenue, countryCode } = countryData[
      country
    ];

    combinedList.push({
      country: countryCode,
      count_of_users: total || 0,
      count_of_users_by_order: enumerate || 0,
      count_of_orders: count || 0,
      orders_total_price: price || 0,
      orders_total_price_by_country: revenue || 0,
    });
  }

  let updatedUserRegCountryList = combinedList
    ?.map((user) => {
      const updatedUser = changeKeys(user, keyMappingRegUsers);
      updatedUser.strong = countryMap[user.country] || user.country;

      updatedUser.price =
        countryMap[user.orders_total_price] !== null ||
        countryMap[user.orders_total_price] !== undefined
          ? formatMoney(user.orders_total_price)
          : 0;

      updatedUser.revenue =  
        countryMap[user.orders_total_price_by_country] !== null ||
        countryMap[user.orders_total_price_by_country] !== undefined
          ? formatMoney(user.orders_total_price_by_country)
          : 0;

      return updatedUser;
    })
    .sort((a, b) => b.label - a.label);

  updatedUserRegCountryList.unshift({
    strong: '   ',
    label: 'Reg Users Total',
    enumerate: 'New User Order Count',
    count: 'Total Order Count',
    price: 'New Total Revenue',
    revenue: 'Total Revenue',
  });

  const updatedTotalProjectPrice = (data, keyMapping) => {
    return (
      data &&
      Object.entries(data).map(([key, value]) => {
        const strong = keyMapping[key] || key;
        const formattedValue = formatMoney(parseFloat(value));
        return { strong, label: formattedValue };
      })
    );
  };

  const transformTotalProjectData = updatedTotalProjectPrice(
    dashboardData?.materialMilledData?.material_price_total_to_be_milled?.totalProjectPrice[0],
    keyMappingProjects
  );

  const fetchData = async (dateFrom = null, dateTo = null) => {
    try {
      const getRequest = (endpoint) => axios.get(endpoint, {
        params: dateFrom && dateTo ? { date_from: dateFrom, date_to: dateTo } : {},
      });

      const endpoints = [
        '/api/dashboard/order',
        '/api/dashboard/user/live',
        '/api/dashboard/user/country/list',
        '/api/dashboard/order/data',
        '/api/dashboard/order/material/list',
        '/api/dashboard/order/avg/amount/graph',
        '/api/dashboard/purchase/frequency/graph',
        '/api/dashboard/order/amount/graph',
        '/api/dashboard/order/count/graph',
        '/api/dashboard/uploads',
        '/api/dashboard/unique/customers',
        '/api/dashboard/order/total',
        '/api/dashboard/quote/total',
        '/api/dashboard/uploads/count',
        '/api/dashboard/quote/count',
        '/api/dashboard/user/count',
        '/api/dashboard/uploads/data',
        '/api/dashboard/uploads/data/graph',
        '/api/dashboard/orders/info',
        '/api/dashboard/user/price',
        '/api/dashboard/user/countries',
        '/api/dashboard/user/countries/orders',
        '/api/dashboard/material/milled',
        '/api/dashboard/countries/order',
      ];
  
      const responses = await Promise.all(endpoints.map(getRequest));

      const keys = [
        'orderData', 'liveUserData', 'userCountryListData', 'orderDataGraph', 'orderMaterialListData',
        'avgAmountGraphData', 'purchaseFrequencyGraphData', 'orderAmountGraphData', 'orderCountGraphData',
        'uploadsData', 'uniqueCustomersData', 'orderTotalData', 'quoteTotalData', 'uploadsCountData',
        'quoteCountData', 'userCountData', 'uploadsGraphData', 'uploadsDataGraph', 'ordersInfoData',
        'userPriceData', 'userCountriesData', 'userCountriesOrdersData', 'materialMilledData',
        'countriesOrderData',
      ];

      const data = responses.reduce((acc, response, index) => {
        acc[keys[index]] = response.data;
        return acc;
      }, {});
  
      setDashboardData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const getProductionData = async () => {
    let { data } = await initApi().get(API_URLS.GET_DELIVERY_DAYS);
    let formatResponse = data.map((item) => {
      return {
        key: item.id,
        strong: `${item.days} dagen`,
        label: `Huidig ${Number(item.production_time + 1).toFixed(2)} dagen`,
      };
    });

    setProductionData(formatResponse);
  };

  const handleChangeDateValues = async (values) => {
    if (values && values[0] && values[1]) {
      const dateFrom = new Date(values[0]);
      const dateTo = new Date(values[1]);

      await setDateValues([dateFrom, new Date(values[1].toISOString())]);

      const formattedDateFrom = formatDateWithTimezoneOffset(dateFrom);
      const formattedDateTo = formatDateWithTimezoneOffset(dateTo);

      fetchData(formattedDateFrom, formattedDateTo);
    } 
  };

  // Function to format date with timezone offset
  const formatDateWithTimezoneOffset = (date) => {
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneSign}${padNumber(
      timezoneOffsetHours
    )}:${padNumber(timezoneOffsetMinutes)}`;
  };

  // Helper function to pad numbers with leading zeros
  const padNumber = (num) => {
    return num < 10 ? '0' + num : num;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 text-right mb-4">
          <DateRangePicker
            value={dateValues}
            onChange={handleChangeDateValues}
          />
        </div>
      </div>
      <div className="row cutwise-stats">
        <div className="col-12">
          <div className="row">
            <StatsDisplay
              colWidth={3}
              title="Gebruikers"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{dashboardData?.userCountData?.users}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  {dashboardData?.liveUserData} gebruikers actief
                </span>
                <h1>{formatMoney(dashboardData?.userPriceData?.current_users_projects_price_amount)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Totale bestellingen
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title="Aanmelding waarde"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(parseFloat(dashboardData?.orderTotalData?.orders_total / dashboardData?.userCountData?.users).toFixed(2)) || '€ 0'}</h1>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={` ${dashboardData?.orderData?.orders} Orders`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(dashboardData?.orderTotalData?.orders_total)}</h1>

                <span className={'text-muted font-weight-bold d-block'}>
                  Gem. aankoopwr: €{parseFloat(dashboardData?.orderDataGraph?.order_average_amount).toFixed(2)}
                </span>
                <span className={'text-muted font-weight-bold d-block'}>
                  Gem. aankoopfr: {dashboardData?.orderDataGraph?.purchase_frequency}
                </span>
                <span className={'text-muted font-weight-bold d-block'}>
                  Gem. klantwr: €{parseFloat(dashboardData?.orderDataGraph?.customer_value).toFixed(2)}
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={`  ${dashboardData?.orderData?.orders} Totaal Aantal Bestellingen`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(dashboardData?.ordersInfoData?.total_price_materials?.total_data?.shipping_costs)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Totale verzendkosten
                </span>
                <br />
                <h1>{formatMoney(dashboardData?.ordersInfoData?.total_price_materials?.total_data?.startup_costs)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Totale opstartkosten
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title="Uploads naar orders"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatPercentage((dashboardData?.orderData?.orders / dashboardData?.uploadsCountData?.uploads) * 100)}</h1>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={` ${dashboardData?.uploadsCountData?.uploads} Uploads`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(dashboardData?.uploadsData?.uploads_total)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  excl. offerte & orders
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title={` ${dashboardData?.quoteCountData?.quotes} Offertes`}
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(dashboardData?.quoteTotalData?.quotes_total)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  {' '}
                  aantal offertes
                </span>
              </div>
            </StatsDisplay>

            <StatsDisplay
              colWidth={3}
              title="Customer Lifetime Value"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{formatMoney(dashboardData?.orderDataGraph?.total_lifecycle)}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  Total customer lifetime : {dashboardData?.orderDataGraph?.total_lifespan.toFixed(4)}
                </span>
              </div>
            </StatsDisplay>
            <StatsDisplay
              colWidth={3}
              title="Unieke klanten met bestelling"
              className="kt-widget26"
            >
              <div
                className={'flex-grow-1'}
                // eslint-disable-next-line react/no-unknown-property
                align="center"
              >
                <h1>{dashboardData?.uniqueCustomersData?.unique_customers}</h1>
                <span className={'text-muted font-weight-bold d-block'}>
                  {((dashboardData?.uniqueCustomersData?.unique_customers / dashboardData?.userCountData?.users) * 100).toFixed(2)}% gebruiker naar
                  klant
                </span>
              </div>
            </StatsDisplay>

            <AreaGraph
              colWidth={12}
              dataSet={dashboardData?.orderCountGraphData?.order_count_graph}
              styles={{ area: { fill: '#D80000', stroke: '#D80000' } }}
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Orders' }}
              title={`Aantal Orders : ${formatMoney(dashboardData?.orderTotalData?.orders_total)}`}
              className="kt-widget26"
              opacity={0.5}
              height={200}
            />

            <VerticalBarGraph
              colWidth={12}
              dataSet={dashboardData?.avgAmountGraphData?.order_average_amount_graph}
              title="Gem. Orderwaarde"
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Gem.', type: 'money' }}
              opacity={0.5}
              height={200}
            />

            <VerticalBarGraph
              colWidth={12}
              dataSet={dashboardData?.orderAmountGraphData?.order_amount_graph}
              title="Totale Orderwaarde"
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Gem.', type: 'money' }}
              opacity={0.5}
              height={200}
            />

            <AreaGraph
              colWidth={12}
              dataSet={dashboardData?.purchaseFrequencyGraphData?.purchase_frequency_graph}
              styles={{ area: { fill: '#D80000', stroke: '#D80000' } }}
              title={`Gem. aankoopfrequentie`}
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Percentage.' }}
              opacity={0.5}
              height={200}
            />

            <VerticalBarGraph
              colWidth={12}
              dataSet={dashboardData?.uploadsDataGraph?.uploads_data_graph}
              title="Totaal aantal uploads exclusief offertes & orders"
              className="kt-widget26"
              xAxis={{ show: true, title: 'Maand' }}
              yAxis={{ show: true, title: 'Aantal' }}
              opacity={0.5}
              height={200}
            />

            <TopListDisplay
              title="Omzet per Materiaalcategorie"
              listData={updatedOrderPriceByCategory}
            />

            <TopListDisplay
              title="Omzet per bewerkingscategorie"
              listData={updatedOrderPriceByMilled}
            />

            <TopListDisplay
              title="Totale bestelling, inclusief kortingen, verzendkosten en opstartkosten"
              listData={transformTotalProjectData}
            />

            <TopListDisplay title="Productie tijd" listData={productionData} />

            <TopListDisplay
              title="Meest gekochte materialen"
              listData={dashboardData?.orderMaterialListData?.order_material_list}
            />

            <TopListDisplay
              colWidth={6}
              title="landen van geregistreerde gebruikers"
              listData={updatedUserRegCountryList}
            />
          </div>
        </div>
      </div>
    </>
  );
}
