import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import UserTable from '@material-table/core';
import { Button, Modal } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { deleteUser, getUsersPage } from '../../../store/users/actions';
import { ROUTES } from '../../../common/constants/routes/routes';
import { authActions } from '../../../store/ducks/auth.duck';

function MasterPage(props) {
  const { signInAsUser, dataUsers, pagination, isProcessing } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const pagePath = history.location.pathname;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(true);
  const columns = [
    { title: 'Company', field: 'company.name' },
    { title: 'First name', field: 'first_name' },
    { title: 'Last name', field: 'last_name' },
    { title: 'E-mail', field: 'email' },
    { title: '# Orders', field: 'count_orders' },
    { title: '# Offertes', field: 'count_quotes' },
    { title: '# Uploads', field: 'count_concepts' },
  ];

  useEffect(() => {
    dispatch(getUsersPage());
  }, []);

  useEffect(() => {
    updateData();
  }, [dataUsers]);

  useEffect(() => {
    const getLocalStorage = async () => {
      let lastVisitedPage = JSON.parse(localStorage.getItem(`${pagePath}`));
      if (lastVisitedPage) {
        setCurrentPage(lastVisitedPage);
      }
      setLoading(false);
    };
    if (!isProcessing) {
      getLocalStorage();
    }
  }, [isProcessing]);

  useEffect(() => {
    updateData();
  }, [currentPage, pageSize]);

  const { count } = pagination;

  // const navigateTo = ({id}) => {
  //     history.push(`${ROUTES.USERS}/details/${id}`);
  // }

  const handleCreate = () => {
    history.push(`${ROUTES.USERS}/create`);
  };

  const updateData = () => {
    if (dataUsers) {
      setData(dataUsers);
      setSearchData(dataUsers);
    }
  };

  const changePage = async (page) => {
    setCurrentPage(page);
    await localStorage.setItem(`${pagePath}`, page);
  };

  const changePerPage = (per_page) => {
    setPageSize(per_page);
  };

  const confirmDeleteItem = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const searchChange = (pattern) => {
    pattern = pattern.toLowerCase();
    let searchResult = searchData.filter(function(item) {
      return (
        item.company?.name.toLowerCase().includes(pattern) ||
        item.first_name.toLowerCase().includes(pattern) ||
        item.last_name.toLowerCase().includes(pattern) ||
        item.email.toLowerCase().includes(pattern)
      );
    });
    setData(searchResult);
  };

  const deleteItem = () => {
    dispatch(
      deleteUser({
        id: deleteId,
        successCallBack: () => {
          dispatch(getUsersPage());
        },
      })
    );
    closeDialog();
  };

  const closeDialog = () => {
    setOpen(false);
    setDeleteId();
  };

  const signInAdminAsUser = (id) => {
    try {
      signInAsUser(id, history);
    } catch (err) {
      console.error('Something went wrong ', err);
    }
  };

  const confirmModal = () => {
    return (
      <Modal show={open} onHide={closeDialog} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Confirm that you would to delete the selected item.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button onClick={deleteItem}>Delete</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function navigateToUser({ id }) {
    history.push(`/users/details/${id}`);
  }

  return isProcessing || loading ? (
    'Loading...'
  ) : (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-section">
            <div className="kt-section__content">
              <UserTable
                title="Users"
                columns={columns}
                data={data}
                onRowClick={(evt, selectedRow) => navigateToUser(selectedRow)}
                page={currentPage}
                totalCount={count}
                onPageChange={changePage}
                onSearchChange={searchChange}
                onChangeRowsPerPage={changePerPage}
                searchable={false}
                actions={[
                  {
                    title: 'SIGN_IN',
                    icon: ExitToAppIcon,
                    tooltip: 'Sign In',
                    onClick: (event, rowData) => signInAdminAsUser(rowData.id),
                  },
                  {
                    title: 'AD',
                    icon: EditIcon,
                    tooltip: 'Edit',
                    onClick: (event, rowData) =>
                      history.push(`${ROUTES.USERS}/edit/${rowData.id}`),
                  },
                  {
                    title: 'AD',
                    icon: DeleteIcon,
                    tooltip: 'Delete',
                    onClick: (event, rowData) => confirmDeleteItem(rowData.id),
                  },
                  {
                    icon: 'add',
                    tooltip: 'Add',
                    isFreeAction: true,
                    onClick: () => handleCreate(),
                  },
                ]}
                options={{
                  pageSize: pageSize,
                  actionsColumnIndex: -1,
                  search: true,
                  maxColumnSort: 'all_columns',
                  // padding: 'dense',
                  headerStyle: {
                    color: '#D80000',
                    fontSize: 13,
                  },
                  body: {
                    fontSize: 13,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {confirmModal()}
    </>
  );
}

MasterPage.propTypes = {
  signInAsUser: PropTypes.func,
  dataUsers: PropTypes.array,
  isProcessing: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ users: { data, pagination, isProcessing } }) => ({
  dataUsers: data,
  pagination,
  isProcessing,
});

const mapDispatchToProps = {
  signInAsUser: (id, history) => authActions.signInAsUser({ id, history }),
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
